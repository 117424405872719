.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.TitleText {
  font-family: "Titillium Web", sans-serif;
  font-size: calc(20px + 2vmin);
  line-height: 1.125;
}

.App-link {
  color: #61dafb;
}

.Titillium {
  font-family: "Titillium Web", sans-serif;
}


.loader120 {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #0e447b;
  /* Shippers Edge Dark Blue */
  border-bottom: 16px solid #32abd4;
  /* Shippers Edge Light Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader60 {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #0e447b;
  /* Shippers Edge Dark Blue */
  border-bottom: 8px solid #32abd4;
  /* Shippers Edge Light Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* f3f3f3 */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner>div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4)
  }

  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}



.pointerElement {
  cursor: pointer;
}

.vertical-divider {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  margin: 0;
  padding: 0;
  width: auto;
  height: 100%;
  line-height: 0;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
}

.vertical-divider:before,
.vertical-divider:after {
  position: absolute;
  left: 50%;
  content: '';
  z-index: 9;
  border-left: 1px solid rgba(34, 36, 38, .15);
  border-right: 1px solid rgba(255, 255, 255, .1);
  width: 0;
  height: 100%;
}

.row-divided>.vertical-divider {
  height: 100%;
}

.row-noWrap {
  flex-wrap: nowrap
}

.optionsColumn {
  height: 35rem;
  width: 13rem;
  overflow: auto;
}

.dockHeader {
  width: 13rem;
}

.appointmentsRow {
  flex-wrap: 'nowrap';
  justify-content: 'center';
  display: 'flex';
}

.appointmentButton {
  width: 160px;
  margin: 5px
}

@media screen and (max-width: 1200px) {
  .optionsColumn {
    height: 25rem !important;
    width: 13rem;
    overflow: auto;
  }

  .dockHeader {
    width: 13rem;
  }
}

@media screen and (max-width: 800px) {
  .row-noWrap {
    flex-wrap: wrap;
  }

  .appointmentsRow {
    flex-wrap: 'wrap';
    justify-content: 'center';
    display: 'flex';
  }

  .optionsColumn {
    height: 35rem;
    width: 15rem;
    overflow: auto;
  }

  .dockHeader {
    width: 15rem;
  }

  .appointmentButton {
    width: 200px;
    margin: 5px
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import "./styles/bootstrap4.min.css";

.e-schedule .e-block-appointment {
  background-color: #808080;
  color: #E0E0E0;
  font-size: 1rem;
}


.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear;
}


.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.hidden {
  display: none
}

.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.scheduleComponentContainer {
  /* height: 1000px */
  margin-bottom: 100px;
}

#dockEventNumber {
  color: #808080;
}


/* Template CSS */

.xlargeFontSize {
  font-size: x-large;
}

.xsmallFontSize {
  font-size: x-small;
}

.smallFontSize {
  font-size: small;
}

.mediumFontSize {
  font-size: medium;
}

.e-print-schedule {
  color: #000 !important;
}